import { Reference } from "@apollo/client/cache";
import { relayStylePagination } from "@apollo/client/utilities";

// NOTE: Override relayStylePagination policy for include totalCount in the connection.
export function relayStylePaginationWithTotalCount<TNode = Reference>(keyArgs?: any): any {
  const policy = relayStylePagination<TNode>(keyArgs);

  return {
    keyArgs: policy.keyArgs,
    read(existing, options) {
      if (!policy.read) {
        return undefined;
      }

      if (!existing) {
        return policy.read(existing, options);
      }

      return policy.read(
        {
          ...existing,
          edges: existing.edges || [],
          pageInfo: existing.pageInfo,
        },
        options
      );
    },
    // NOTE: `merge` does not return undefined if it is defined, but avoids a type error if undefined is returned in the method
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    merge(existing, incoming, options) {
      if (!policy.merge) {
        return undefined;
      }

      if (typeof policy.merge === "boolean") {
        return policy.merge;
      }

      if (!existing) {
        return policy.merge(existing, incoming, options);
      }

      return policy.merge(
        {
          ...existing,
          edges: existing.edges || [],
          pageInfo: existing.pageInfo,
        },
        incoming,
        options
      );
    },
  };
}
