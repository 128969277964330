import React, { FC, useEffect } from "react";
import { Center, Box, Flex, Text, Spacer, CloseButton } from "@chakra-ui/react";

export type KindType = "alert" | "success";

export type ToastMessageType = {
  key: string;
  kind: KindType;
  message: string;
};

type Props = {
  onClose: (key: string) => void;
  toastMessage: ToastMessageType;
};

export const ToastMessage: FC<Props> = ({ onClose, toastMessage }) => {
  const handleClick = () => {
    onClose(toastMessage.key);
  };

  useEffect(() => {
    setTimeout(() => {
      onClose(toastMessage.key);
    }, 5000);
  }, [onClose, toastMessage.key]);

  return (
    <Box
      position="relative"
      left="-50%"
      bg={toastMessage.kind === "success" ? "green.500" : "red.500"}
      minW="320px"
      minH="44px"
      borderRadius="md"
      color="white"
      p="10px"
      _last={{ mb: "10px" }}
    >
      <Flex>
        <Center>
          <Text fontSize="sm" fontWeight="600">
            {toastMessage.message}
          </Text>
        </Center>
        <Spacer />
        <CloseButton onClick={handleClick} color="white" colorScheme={toastMessage.kind === "success" ? "green" : "red"} size="sm" ml="5px" />
      </Flex>
    </Box>
  );
};
