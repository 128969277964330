import { ApolloProvider } from "@apollo/client";
import React, { ReactChild } from "react";
import { IntlProvider } from "react-intl";
import { CurrentUserContextProvider, useCurrentUser } from "~/contexts/CurrentUserContextProvider";
import { graphqlClient } from "~/graphql/client";
import { handleIntlError } from "~/i18n/i18n";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraUiTheme } from "~/utils/chakraUiTheme";
import { i18n } from "initializers/i18n";

interface Props {
  children: ReactChild;
}

const ContextProviderOnCurrentUserContext: React.FC<Props> = ({ children }) => {
  const currentUser = useCurrentUser();

  return (
    <IntlProvider locale={currentUser.locale || i18n.locale} onError={handleIntlError}>
      <ApolloProvider client={graphqlClient}>
        <ChakraProvider theme={chakraUiTheme}>{children}</ChakraProvider>
      </ApolloProvider>
    </IntlProvider>
  );
};

export const ContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <CurrentUserContextProvider>
      <ContextProviderOnCurrentUserContext>{children}</ContextProviderOnCurrentUserContext>
    </CurrentUserContextProvider>
  );
};
