import { canUseDOM } from "exenv";

const retriableImport = (importFunction, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    importFunction.then(resolve).catch((err) => {
      if (!canUseDOM) {
        throw err;
      }

      setTimeout(() => {
        if (retriesLeft === 1) {
          // Retry the max number of times and reload
          window.location.reload();
          return;
        }

        retriableImport(importFunction, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
};

export const containerMap = {
  DashboardIndex: retriableImport(import("./features/dashboard/DashboardIndex")),
  DepartmentIndex: retriableImport(import("./features/departments/DepartmentIndex")),
  FlyerIndex: retriableImport(import("./features/flyers/FlyerIndex")),
  FlyerShow: retriableImport(import("./features/flyers/FlyerShow")),
  FlyerEdit: retriableImport(import("./features/flyers/FlyerEdit")),
  FlyerNew: retriableImport(import("./features/flyers/FlyerNew")),
  FlyerApplicationMailIndex: retriableImport(import("./features/flyer_applications/FlyerApplicationMailIndex")),
  LegacyFlyerApplicationNew: retriableImport(import("./features/flyer_applications/LegacyFlyerApplicationNew")),
  PublicFlyerApplicationNew: retriableImport(import("./features/flyer_applications/PublicFlyerApplicationNew")),
  FlyerOverviewShow: retriableImport(import("./features/flyers/FlyerOverviewShow")),
  FlyerOverviewEdit: retriableImport(import("./features/flyers/FlyerOverviewEdit")),
  ProspectIndex: retriableImport(import("./features/prospects/ProspectIndex")),
  ProspectRegisterFormShow: retriableImport(import("./features/prospects/ProspectRegisterFormShow")),
  ToastMessageContainer: retriableImport(import("./components/ToastMessageContainer")),
  UserMenuButtonContainer: retriableImport(import("./features/users/UserMenuButtonContainer")),
  NotificationButtonContainer: retriableImport(import("./components/NotificationButtonContainer")),
  ProspectReferralScoreContainer: retriableImport(import("./features/prospects/ProspectReferralScoreContainer")),
  ProspectJobChangeIntentionContainer: retriableImport(import("./features/prospects/ProspectJobChangeIntentionContainer")),
  ProspectNew: retriableImport(import("./features/prospects/ProspectNew")),
  ProspectEdit: retriableImport(import("./features/prospects/ProspectEdit")),
  ProspectShow: retriableImport(import("./features/prospects/ProspectShow")),
  SettingIndex: retriableImport(import("./features/setting/SettingIndex")),
  ErrorMessageList: retriableImport(import("./components/ErrorMessageList")),
  AccessTokenListContainer: retriableImport(import("./features/access_tokens/AccessTokenListContainer")),
  UserProfileEdit: retriableImport(import("./features/users/UserProfileEdit")),
  UserAssignProspectListContainer: retriableImport(import("./features/users/UserAssignProspectListContainer")),
  UserActivityListContainer: retriableImport(import("./features/users/UserActivityListContainer")),
  UserIndex: retriableImport(import("./features/users/UserIndex")),
  UserShow: retriableImport(import("./features/users/UserShow")),
  TeamSettingIndex: retriableImport(import("./features/team_setting/TeamSettingIndex")),
  UserLocaleSettingContainer: retriableImport(import("./features/users/UserLocaleSettingContainer")),
  SessionsNew: retriableImport(import("./components/SessionsNew")),
  OrganizationsSelect: retriableImport(import("./components/OrganizationsSelect")),
  NoteIndex: retriableImport(import("./features/notes/NoteIndex")),
  NoteNew: retriableImport(import("./features/notes/NoteNew")),
  NoteEdit: retriableImport(import("./features/notes/NoteEdit")),
  NoteShow: retriableImport(import("./features/notes/NoteShow")),
  RichTextRenderer: retriableImport(import("./components/RichTextRenderer")),
  PublicFlyerShow: retriableImport(import("./features/public_flyers/PublicFlyerShow")),
  LegacyPublicFlyerShow: retriableImport(import("./features/public_flyers/LegacyPublicFlyerShow")),
  PublicFlyerAlreadyApplied: retriableImport(import("./features/flyer_applications/PublicFlyerAlreadyApplied")),
  LegacyPublicFlyerApplicationThanks: retriableImport(import("./features/flyer_applications/LegacyPublicFlyerApplicationThanks")),
  PublicFlyerApplicationThanks: retriableImport(import("./features/flyer_applications/PublicFlyerApplicationThanks")),
  MobilePushMessageIndex: retriableImport(import("./features/mobile_push/MobilePushMessageIndex")),
  MobilePushMessageNew: retriableImport(import("./features/mobile_push/MobilePushMessageNew")),
  MobilePushMessageEdit: retriableImport(import("./features/mobile_push/MobilePushMessageEdit")),
  MobilePushMessageShow: retriableImport(import("./features/mobile_push/MobilePushMessageShow")),
  MailMessageIndex: retriableImport(import("./features/mail_messages/MailMessageIndex")),
  MailMessageNew: retriableImport(import("./features/mail_messages/MailMessageNew")),
  MailMessageEdit: retriableImport(import("./features/mail_messages/MailMessageEdit")),
  MailMessageShow: retriableImport(import("./features/mail_messages/MailMessageShow")),
  ProspectProcessItemIndex: retriableImport(import("./features/prospects/ProspectProcessItemIndex")),
  FlyerShareIndex: retriableImport(import("./features/flyer_shares/FlyerShareIndex")),
  BadRequestError: retriableImport(import("./features/errors/BadRequestError")),
  ForbiddenError: retriableImport(import("./features/errors/ForbiddenError")),
  InternalServerError: retriableImport(import("./features/errors/InternalServerError")),
  NotFoundError: retriableImport(import("./features/errors/NotFoundError")),
  UnprocessableEntityError: retriableImport(import("./features/errors/UnprocessableEntityError")),
  JobTitleIndex: retriableImport(import("./features/job_titles/JobTitleIndex")),
  OccupationIndex: retriableImport(import("./features/occupations/OccupationIndex")),
  UserImport: retriableImport(import("./features/users/UserImport")),
  InquiriesNew: retriableImport(import("./features/inquiries/InquiriesNew")),
  InquiriesThanks: retriableImport(import("./features/inquiries/InquiriesThanks")),
  PublicNoteShow: retriableImport(import("./features/public_notes/PublicNoteShow")),
  PageSettingIndex: retriableImport(import("./features/page_setting/PageSettingIndex")),
  PageSettingEdit: retriableImport(import("./features/page_setting/PageSettingEdit")),
  ProspectPortalIndex: retriableImport(import("./features/portal/ProspectPortalIndex")),
  ProspectPortalFlyersIndex: retriableImport(import("./features/portal/ProspectPortalFlyersIndex")),
  ProspectPortalNoteIndex: retriableImport(import("./features/portal/ProspectPortalNoteIndex")),
  LocationIndex: retriableImport(import("./features/locations/LocationIndex")),
  FlyerApplicationIndex: retriableImport(import("./features/flyer_applications/FlyerApplicationIndex")),
};
