import { z } from "zod";
import { t } from "~/i18n/i18n";

/**
 * NOTE Set up a custom ErrorMap with translated validation
 * error messages for required fields.
 * If you don't do this, the validation errors
 * for fields in the required settings will default to the English display.
 * @see https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md#error-map-priority
 * @param issue
 * @param ctx
 */
export const zodCustomErrorMap: z.ZodErrorMap = (issue, ctx) => {
  // @ref https://github.com/colinhacks/zod/blob/82e72ad359a0758e8bba69794c2880bb6d96be1e/src/locales/en.ts#L7-L12
  let message: string;
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === z.ZodParsedType.undefined) {
        message = t("javascript_component.zod_custom_error_map.required");
      } else if (issue.expected === z.ZodParsedType.number) {
        message = t("javascript_component.zod_custom_error_map.require_number");
      } else {
        message = ctx.defaultError;
      }
      break;
    case z.ZodIssueCode.invalid_enum_value:
      message = t("javascript_component.zod_custom_error_map.invalid_enum_value");
      break;
    case z.ZodIssueCode.too_big:
      if (issue.type === "string") {
        message = t("javascript_component.zod_custom_error_map.too_big.string", { maximum: issue.maximum });
      } else {
        message = ctx.defaultError;
      }
      break;
    case z.ZodIssueCode.too_small:
      if (issue.type === "string") {
        message = t("javascript_component.zod_custom_error_map.too_small.string", { minimum: issue.minimum });
      } else if (issue.type === z.ZodParsedType.array) {
        message = t("javascript_component.zod_custom_error_map.too_small.array", { minimum: issue.minimum });
      } else {
        message = ctx.defaultError;
      }
      break;
    default:
      message = ctx.defaultError;
  }
  return { message };
};
z.setErrorMap(zodCustomErrorMap);
