import { ApolloClient, ApolloLink, InMemoryCache, Operation, ServerError, ServerParseError } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import fetch from "cross-fetch";
import { GraphQLError, OperationDefinitionNode, print } from "graphql";
import { setToastMessage, setToastMessageForNextPage } from "../components/ToastMessageContainer";
import possibleTypes from "./generated/possibleTypesResultData";
import { relayStylePaginationWithTotalCount } from "../utils/relayStylePaginationWithTotalCount";
import { t } from "~/i18n/i18n";

const requestHeaders = {
  "X-Requested-With": "apollo",
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.error(`[GraphQL error]: `, message, print(operation.query)));
  }

  if (networkError) {
    console.error(`[Network error]: `, networkError);
  }
});

const httpOptions = {
  uri: "/graphql",
  headers: requestHeaders,
  credentials: "same-origin",
  fetch,
  fetchOptions: {
    referrerPolicy: "unsafe-url",
  },
};

const batchHttpLink = new BatchHttpLink(httpOptions);
const uploadLink = createUploadLink(httpOptions);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOperationDefinitionNode = (node: any): node is OperationDefinitionNode => node.operation !== undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMutationNode = (node: any): node is OperationDefinitionNode => isOperationDefinitionNode(node) && node.operation === "mutation";
const isContainMutation = (operation: Operation) => operation.query.definitions.findIndex((node) => isMutationNode(node)) !== -1;

export const REQUEST_ENTITY_TOO_LARGE = 413;

export const isRequestEntityTooLargeError = (status: number) => {
  return status === REQUEST_ENTITY_TOO_LARGE;
};

export const graphqlClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePaginationWithTotalCount(["where", "orderBy"]),
          activities: relayStylePaginationWithTotalCount(["where"]),
          schedules: relayStylePaginationWithTotalCount(["where"]),
          flyers: relayStylePaginationWithTotalCount(["where"]),
        },
      },
      User: {
        fields: {
          accessTokenLogs: relayStylePaginationWithTotalCount(),
          notifications: relayStylePaginationWithTotalCount(),
          activities: relayStylePaginationWithTotalCount(),
          assigns: relayStylePaginationWithTotalCount(),
        },
      },
    },
  }),
  link: ApolloLink.from([errorLink, ApolloLink.split((operation) => isContainMutation(operation), uploadLink, batchHttpLink)]),
  ssrMode: typeof window === "undefined",
});

// Don't use if need to separate error actions by `extentions.code`
export const defaultGraphqlErrorHandler = (graphQLErrors: ReadonlyArray<GraphQLError>): void => {
  graphQLErrors.forEach(({ message, extensions }) => {
    switch (extensions?.code) {
      case "CUSTOM_PROPERTY_OPTION_REQUIRES_ACTIVE_AT_LEAST_ONE": {
        setToastMessage("alert", t("javascript_component.graphql_client.error_code.custom_property_option_requires_active_at_least_one"));
        break;
      }
      default: {
        if (message !== "") {
          setToastMessage("alert", message);
        }
        break;
      }
    }
  });
};

// Don't use if need to separate error actions by `extentions.code`
export const defaultGraphqlErrorHandlerForNextPage = (graphQLErrors: ReadonlyArray<GraphQLError>): void => {
  graphQLErrors.forEach(({ message, extensions }) => {
    if (message !== "") {
      setToastMessageForNextPage("alert", message);
    }
  });
};

export const graphqlInvalidErrorHandler = (errors: ReadonlyArray<GraphQLError>): Record<string, any> => {
  const formMessages = {};

  errors.forEach((error) => {
    switch (error.extensions?.code) {
      case "BAD_REQUEST":
        if (error.extensions.attribute) {
          const attribute = error.extensions.attribute as string;
          if (formMessages[attribute]) {
            formMessages[attribute].push(error.message);
          } else {
            formMessages[attribute] = [error.message];
          }
        } else if (error.message !== "") {
          setToastMessage("alert", error.message);
        }

        break;
      default:
        if (error.message !== "") {
          setToastMessage("alert", error.message);
        }
    }
  });

  return formMessages;
};

// All connections require `first` or `last` param,
// so you can use this constant to "get everything in a connection".
const GRAPHQL_INT_MAX = 2147483647; // GraphQL's int is signed 32-bit integer
export const CONNECTION_ALL_COUNT = GRAPHQL_INT_MAX;
