import { Scope, TranslateOptions } from "i18n-js";
import React from "react";
import { FormattedMessage } from "react-intl";
import { i18n } from "initializers/i18n";

export const handleIntlError = (): void => {
  // Prevent display error message of missing translation.
  // Translation message provide from I18n-js and set component via defaultMessage prop
};

export function t(scope: Scope, options?: TranslateOptions): string {
  return i18n.t(scope, options);
}

export function formatted(scope: string, values?: React.ComponentProps<typeof FormattedMessage>["values"], options?: TranslateOptions): JSX.Element {
  const message = t(
    scope,
    Object.keys(values || {}).reduce((o, k) => {
      return { ...o, [k]: `{${k}}` };
    }, options || {})
  );
  return <FormattedMessage id={scope} defaultMessage={message} values={values} />;
}
