import { init, onLoad, setUser } from "@sentry/browser";
import { currentUserData } from "~/contexts/currentUserContext";
import { CaptureConsole } from "@sentry/integrations";

onLoad(() => {
  init({
    dsn: "https://45c46c300ed1438e992989e7329c1b94@o286461.ingest.sentry.io/2315087",
    environment: process.env.SENTRY_ENV,
    enabled: process.env.SENTRY_ENV === "staging" || process.env.SENTRY_ENV === "production",
    debug: process.env.SENTRY_ENV !== "production",
  });

  setUser({
    id: currentUserData.identifierId,
    user_id: currentUserData.userId,
    organization_id: currentUserData.organizationId,
    organization_subdomain: currentUserData.organizationSubdomain,
  });

  // eslint-disable-next-line no-console
  console.log(`Loaded sentry as ${process.env.SENTRY_ENV}`);
});
