import { extendTheme, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxTheme = defineMultiStyleConfig({
  sizes: {
    // モバイル用で variants: right と合わせて使う想定
    // https://github.com/chakra-ui/chakra-ui/blob/7a06603bd61e06e0591bf68d6dd50daad2c336af/packages/theme/src/components/checkbox.ts#L87-L103
    xl: {
      control: {
        width: "1.5em",
        height: "1.5em",
      },
      label: {
        fontSize: "lg",
        marginEnd: "32px",
      },
      icon: {
        fontSize: "xs",
      },
    },
  },
  variants: {
    circularRight: definePartsStyle({
      control: {
        position: "absolute",
        right: 0,
        borderRadius: "100%",
      },
      label: {
        marginStart: "0px",
      },
    }),
  },
});

export const chakraUiTheme = extendTheme({
  semanticTokens: {
    colors: {
      text: {
        default: "gray.800",
      },
      subText: {
        default: "gray.500",
      },
      border: {
        default: "gray.200",
      },
      google: {
        default: "#4285f4",
      },
      hoverBg: {
        default: "gray.50",
      },
    },
  },
  colors: {
    brand: {
      50: "#E9EDFC",
      100: "#7E94F0",
      200: "#6982ED",
      300: "#5370EB",
      400: "#3E5EE8",
      500: "#294DE6",
      600: "#2445CF",
      700: "#203DB8",
      800: "#1C35A1",
      900: "#182E8A",
    },
  },
  components: {
    Button: {
      variants: {
        link: {
          color: "brand.500",
        },
        normal: {
          backgroundColor: "white",
          color: "text",
          border: "1px solid",
          borderColor: "border",
          _hover: {
            bg: "gray.100",
          },
          _active: {
            bg: "gray.200",
          },
        },
        "tiptap-popover-item": {
          color: "white",
          bg: "gray.900",
          _hover: {
            bg: "gray.600",
          },
        },
        "prospect-list-status-group-filter-button": {
          w: "auto",
          h: "auto",
          paddingInline: 6,
          pt: 1,
          pb: 2,
          borderBottomRadius: "none",
          borderBottomWidth: "2px",
          borderBottomColor: "border",
          color: "subText",
          _hover: {
            bg: "gray.100",
          },
        },
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
      variants: {
        unstyled: {
          p: 0,
        },
      },
    },
    Menu: {
      baseStyle: {
        item: {
          _focus: {
            bg: "gray.50",
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "brand.500",
      },
    },
    FormLabel: {
      baseStyle: {
        color: "text",
        fontWeight: 600,
      },
    },
    Tooltip: {
      baseStyle: {
        bg: "white",
        color: "border",
        border: "1px",
      },
    },
    Modal: {
      defaultProps: {
        size: "xl",
      },
      sizes: {
        xl: {
          dialog: {
            maxW: "600px",
          },
        },
      },
      baseStyle: {
        header: {
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomColor: "border",
        },
        body: {
          paddingBottom: 5,
        },
      },
    },
    AlertDialog: {
      defaultProps: {
        size: "xl",
      },
      sizes: {
        xl: {
          dialog: {
            maxW: "600px",
          },
        },
      },
    },
    GeneralBox: {
      baseStyle: {
        bg: "white",
        w: "600px",
        mt: "20px",
        p: "40px",
        border: "1px",
        borderColor: "border",
        borderRadius: "md",
        boxShadow: "sm",
      },
    },
    Checkbox: checkboxTheme,
  },
  styles: {
    global: {
      html: {
        color: "text",
        display: "flex",
        flex: 1,
        fontFamily:
          '-apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif',
        height: "auto",
        minHeight: "100%",
        wordBreak: "break-word",
      },
      body: {
        boxSizing: "border-box",
        height: "auto",
        margin: 0,
        minHeight: "100%",
        width: "100%",
        // native `input type=date` calendar ui is buggy, so don't use it.
        // see: https://refcome.atlassian.net/browse/PRD-1730
        "input::-webkit-calendar-picker-indicator": {
          display: "none",
        },
      },
      h1: {
        fontWeight: "bold",
      },
      a: {
        color: "brand.500",
      },
      "div[data-hypernova-key]": {
        height: "100%",
      },
    },
  },
});
