import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { containerMap } from "~/containerMap";
import { ContextProvider } from "~/contexts/ContextProvider";

const DATA_HYPERNOVA_ID = "data-hypernova-id";
const DATA_HYPERNOVA_KEY = "data-hypernova-key";

// Get list of node and props data from script tag that was generated by Hypernova.
const getPayloads = (key: string): Array<{ node: Element; props: any }> => {
  const nodes = document.querySelectorAll(`div[${DATA_HYPERNOVA_KEY}="${key}"]`);
  return Array.prototype.map.call(nodes, (node: Element) => {
    const id = node.getAttribute(DATA_HYPERNOVA_ID);
    if (!id) {
      return;
    }
    // div tag has a pair script tag
    const script = document.querySelector(`script[${DATA_HYPERNOVA_KEY}="${key}"][${DATA_HYPERNOVA_ID}="${id}"]`);
    if (!script) {
      return { node, props: null };
    }
    // strip "<!--"" and "-->" and replace HTML entities
    const jsonPayload = script.innerHTML;
    const decodeStr = jsonPayload
      .slice("<!--".length, jsonPayload.length - "-->".length)
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">");
    const props = JSON.parse(decodeStr);

    return { node, props };
  });
};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(`script[${DATA_HYPERNOVA_KEY}]`).forEach((el) => {
    const name = el.getAttribute(DATA_HYPERNOVA_KEY);
    if (!name) {
      return;
    }
    containerMap[name]?.then((componentModule: { [key: string]: React.FunctionComponent<any> }) => {
      const component = componentModule[name];
      const payloads = getPayloads(name);
      payloads.forEach((payload: { node: Element; props: any }) => {
        const { node, props } = payload;
        // wrap ContextProvider always
        const wrappedComponent = createElement(ContextProvider, null, createElement(component, props));
        createRoot(node!).render(wrappedComponent);
      });
    });
  });
});
