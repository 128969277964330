import "~/initializers/polyfills";
import "~/initializers/sentry";
import "~/initializers/i18n";
import "~/initializers/rails";
import "~/initializers/react";
import "~/initializers/vanilla";
import "~/initializers/zodCustomErrorMap";

// eslint-disable-next-line no-console
console.log(`loaded as NODE_ENV=${process.env.NODE_ENV}`);
