
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivityResource": [
      "Assign",
      "Comment",
      "Flyer",
      "FlyerApplication",
      "Note",
      "Schedule"
    ],
    "Node": [
      "AccessToken",
      "AccessTokenLog",
      "Activity",
      "Assign",
      "Comment",
      "Department",
      "DepartmentForPublic",
      "Flyer",
      "FlyerApplication",
      "FlyerApplicationFormItem",
      "FlyerApplicationFormItemForPublic",
      "FlyerApplicationFormSetting",
      "FlyerApplicationFormSettingForPublic",
      "FlyerApplicationItem",
      "FlyerApplicationMail",
      "FlyerForPublic",
      "FlyerOverview",
      "FlyerPin",
      "FlyerPinForPublic",
      "FlyerShare",
      "FlyerShareForPublic",
      "IpWhitelist",
      "JobTitle",
      "JobTitleForPublic",
      "Location",
      "LocationForPublic",
      "MailMessage",
      "MobilePushMessage",
      "Note",
      "NoteForPublic",
      "NotePin",
      "NotePinForPublic",
      "NoteReadLog",
      "Notification",
      "Occupation",
      "OccupationForPublic",
      "OccupationGroup",
      "OccupationGroupForPublic",
      "Organization",
      "OrganizationForPublic",
      "Pin",
      "Privacy",
      "Prospect",
      "ProspectAdditionalProfileRequest",
      "ProspectCustomProperty",
      "ProspectCustomPropertyContent",
      "ProspectCustomPropertyOption",
      "ProspectJobChangeIntention",
      "ProspectLink",
      "ProspectMemo",
      "ProspectPortal",
      "ProspectPortalForPublic",
      "ProspectProcessIdentifier",
      "ProspectProcessItem",
      "ProspectProcessStatus",
      "ProspectReferralScore",
      "ProspectRegisterForm",
      "ProspectStatusItemGroup",
      "Schedule",
      "Section",
      "SlackBotChannel",
      "SlackBotIntegration",
      "User",
      "UserIdentifier",
      "UserMobileDevice",
      "UserNotificationSetting"
    ],
    "NotificationActionResource": [
      "Assign",
      "Comment",
      "ProspectAdditionalProfileRequest",
      "ProspectProcessStatus",
      "Schedule"
    ]
  }
};
      export default result;
    