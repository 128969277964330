import { I18n } from "i18n-js";
import { currentUserData } from "../contexts/currentUserContext";
import ja from "../i18n/ja.json";
import en from "../i18n/en.json";

export const i18n = new I18n();

i18n.store(ja);
i18n.store(en);
i18n.defaultLocale = currentUserData.defaultLocale ?? "ja";
i18n.enableFallback = true;
i18n.locale = currentUserData.locale;
