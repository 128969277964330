import { Center, VStack } from "@chakra-ui/react";
import React, { FC, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { v4 as uuid } from "uuid";
import { ToastMessage, ToastMessageType, KindType } from "~/components/ToastMessage";

interface Props {
  toastMessages: Readonly<ToastMessageType[]>;
}

const cookies = new Cookies();
const cookieName = "toastMessage";
const cookieNameForNextPage = "toastMessageForNextPage";

export const setToastMessage = (kind: KindType, message: string): void => {
  cookies.set(cookieName, { kind, message }, { path: "/" });
};

export const setToastMessageForNextPage = (kind: KindType, message: string): void => {
  cookies.set(cookieNameForNextPage, { kind, message }, { path: "/" });
};

export const ToastMessageContainer: FC<Props> = ({ toastMessages }) => {
  const [toasts, setToasts] = useState<ToastMessageType[]>([]);
  const [showToastMessageInterval, setShowToastMessageInterval] = useState<number | null>(null);

  useEffect(() => {
    const toastMessage = cookies.get(cookieNameForNextPage);
    const toastMessageForNextPage: ToastMessageType = { ...toastMessage, key: uuid() };
    setToasts((prevState) => [
      ...prevState,
      ...toastMessages.map((message): ToastMessageType => {
        return { ...message, key: uuid() };
      }),
      ...(toastMessage ? [toastMessageForNextPage] : []),
    ]);
    cookies.remove(cookieNameForNextPage, { path: "/" });
  }, [toastMessages, setToasts]);

  useEffect(() => {
    return () => {
      if (showToastMessageInterval) {
        window.clearInterval(showToastMessageInterval);
      }
    };
  }, [showToastMessageInterval]);

  useEffect(() => {
    setShowToastMessageInterval(
      window.setInterval(() => {
        const toastMessage = cookies.get(cookieName);

        if (toastMessage) {
          if (toastMessage.kind && toastMessage.message) {
            setToasts((prevState) => [...prevState, { key: uuid(), kind: toastMessage.kind, message: toastMessage.message }]);
          }

          cookies.remove(cookieName, { path: "/" });
        }
      }, 1000)
    );
  }, [toastMessages]);

  const handleCloseToast = (key: string) => {
    setToasts((prevState) => prevState.filter((toast) => toast.key !== key));
  };

  return (
    <Center position="fixed" bottom="0px" left="50%" zIndex="toast">
      <VStack spacing={4}>
        {toasts.map((toastMessage) => {
          return <ToastMessage key={toastMessage.key} onClose={handleCloseToast} toastMessage={toastMessage} />;
        })}
      </VStack>
    </Center>
  );
};
